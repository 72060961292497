<template>
  <div class="description-page">
    <h1>Генератор паролей</h1><h3>с возможностью настройки</h3>
  </div>
  <div class="pass-gen">
    <div class="settings-left">
      <p class="item"
         :class="{'item-off': smallLetters !== true, 'item-on': smallLetters}"
         @click="smallLetters = !smallLetters"
      >Маленькие буквы<br>
        <span class="description">{{ (smallSet+smallExceptSet).split('').sort().join('') }}</span>
      </p>

      <p class="item"
         :class="{'item-off': bigLetters !== true, 'item-on': bigLetters}"
         @click="bigLetters = !bigLetters"
      >Большие буквы<br>
        <span class="description">{{ (bigSet+bigExceptSet).split('').sort().join('') }}</span>
      </p>

      <p class="item"
         :class="{'item-off': numbers !== true, 'item-on': numbers}"
         @click="numbers = !numbers; moreNumbers=false"
      >Цифры<br>
        <span class="description">{{ (numberSet+numberExceptSet).split('').sort().join('') }}</span>
      </p>

      <p class="item"
         :class="{'item-off': spec !== true, 'item-on': spec}"
         @click="spec = !spec"
      >Спец символы<br>
        <span class="description">{{ (specSet+specExceptSet).split('').sort().join('') }}</span>
      </p>

      <p class="item"
         :class="{'item-off': exceptSymbols !== true, 'item-on': exceptSymbols}"
         @click="exceptSymbols = !exceptSymbols"
      >Исключить символы со схожим начертанием<br>
        <span class="description">0O`':;,.1Il|</span>
      </p>
    </div>

    <div class="results">
      <button class="btn menlor-border" @click="genPassButton()">Сгенерировать</button>
      <div class="window menlor-border" @click="copyPassToClipboard($event)">
        <p v-for="(password, idx) in resultsPasswords" :key="idx">{{password}}</p>
      </div>
    </div>

    <div class="settings-right">
      <div class="more-numbers">
        <p class="item"
           :class="{'item-off': moreNumbers !== true, 'item-on': moreNumbers}"
           @click="numbers ? moreNumbers = !moreNumbers : moreNumbers = false"
        >Больше цифр<br>
        <span class="description">Большая часть пароля должна быть цифрами</span>
        </p>

      </div>

      <div class="len-pass menlor-border-dash">
        <p class="item">Длинна пароля</p>
        <input type="range" min="6" max="32" v-model="lengthPass">
        <br>
        <input class="menlor-border-input" type="number" min="6" max="32"
               v-model="lengthPass"
               @focusout="checkLengthPass"
        />
      </div>
      <div class="variant-pass menlor-border-dash">
        <p class="item">Кол-во вариантов</p>
        <input type="range" min="3" max="10"
               v-model="variantsPass"
        >
        <br>
        <input class="menlor-border-input" type="number" min="3" max="10"
               v-model="variantsPass"
               @focusout="checkVariantPass"
        />
      </div>
    </div>
  </div>
  <div class="separator"></div>
  <div class="description-page">
    <p>Пароль нельзя назвать безопасным и устойчивым к взлому, если:</p>
      <ul>
        <li>его длина менее 8 символов</li>
        <li>он является просто словом: «monitor», «sinhrofazatron»</li>
        <li>пароль состоит из вашей фамилии, имени, дня рождения или их сочетания: «Glupov2001», <a href="https://youtu.be/-9d2DolpHa8" target="_blank">«MagripaHaripullaevna»</a></li>
        <li>простая последовательность клавиш на клавиатуре: «qwerty123», «zaq1xsw2cde3vfr4»</li>
      </ul>


    <p>Безопасный пароль считается таковым если:</p>
    <ul>
      <li>его длина более 8 символов</li>
      <li>содержит в себе ПРОПИСНЫЕ и строчные буквы</li>
      <li>в него включены цифры</li>
      <li>есть спецсимволы: точки, запятые и пр. (~!@#$%^&*)</li>
    </ul>

  </div>

    <div ref="tooltip" class="tooltip" hidden>{{ tooltipText }}</div>

</template>

<script>
export default {
  name: "PassGenerator",
  data(){
    return{
      smallLetters: true,
      bigLetters: true,
      numbers: true,
      spec: false,
      exceptSymbols: false,
      includeAllSet: true,
      moreNumbers: false,
      lengthPass: 8,
      variantsPass: 3,
      smallSet: 'abcdefghijkmnopqrstuvwxyz',
      smallExceptSet: 'l',
      bigSet: 'ABCDEFGHJKLMNPQRSTUVWXYZ',
      bigExceptSet: 'OI',
      numberSet: '23456789',
      numberExceptSet: '01',
      specSet: '~!@#$%^&*()_-+={}[]\\"<>?/',
      specExceptSet: '|:;,.\'`',
      exceptSet: (this.smallExceptSet + this.bigExceptSet + this.numberExceptSet + this.specExceptSet),
      resultsPasswords: [],
      timeoutHideTooltip: null,
      tooltipText: 'sdf',
    }
  },
  methods: {
    checkLengthPass(){
      if (this.lengthPass < 6){
        this.lengthPass = 6
      }
    },
    checkVariantPass(){
      if (this.variantsPass < 3){
        this.variantsPass = 3
      }
    },
    genPassword(){
      function getShuffleArr(arrForShuffle){
        let tempArray = typeof arrForShuffle === 'string' ? Array.of(...arrForShuffle) : arrForShuffle.slice()

        tempArray.forEach((curVal, i) => {
          const j = Math.round(Math.random() * (tempArray.length-1));
          [tempArray[i], tempArray[j]] = [tempArray[j], tempArray[i]]
        })
        return tempArray.join('')
      }

      function getSetRandom(exceptSymbols){
        return (keyName, setSymbol, exceptSetSymbol) => {
          let genSet = exceptSymbols ? setSymbol : setSymbol + exceptSetSymbol
          genSet = getShuffleArr(genSet)
          let result = ''
          for (let i=0; i<passSettings[keyName]; i++) {
            result += genSet[Math.round(Math.random() * (genSet.length - 1))]
          }
          return result
        }
      }

      let passSettings = {}
      this.smallLetters ? passSettings["small"] = 0 : null
      this.bigLetters ? passSettings["big"] = 0 : null
      this.numbers ? passSettings["numbers"] = 0 : null
      this.spec ? passSettings["spec"] = 0 : null

      // Пароль должен включать хотя бы один символ из каждого набора. Проходим по всем ключам объекта настроек
      for (let item in Object.keys(passSettings)){
        passSettings[Object.keys(passSettings)[item]] = 1
      }
      // вычисляем текущую длину набора символов
      let lengthSymbols = Object.values(passSettings).reduce((a, b) => a + b)

      let tail = this.lengthPass - lengthSymbols
      if(this.moreNumbers){
        const halfPass = Math.floor(this.lengthPass/2)
        // (6)если хвост(4) длиннее половины(3) то в рандом (4)хвост - (3)половина
        // (6)если хвост (2) <= половине, то в пароль хвост + 1
        if (tail > halfPass){
          passSettings['numbers'] = Math.round( Math.random() * (tail-halfPass))+halfPass
        }else{
          passSettings['numbers'] = tail+1
        }
        tail = this.lengthPass - Object.values(passSettings).reduce((a, b) => a+b)
      }

      // проходим по хвосту и добавляем случайные символы

      for (let i=0; i < tail; i++){
        let randIndex = Math.round( Math.random() * (Object.keys(passSettings).length-1))
        passSettings[Object.keys(passSettings)[randIndex]] += 1
      }

      let result = ''
      const setRandom = getSetRandom(this.exceptSymbols)

      if (passSettings["small"]){
        result += setRandom("small", this.smallSet, this.smallExceptSet)
      }
      if (passSettings["big"]){
        result += setRandom("big", this.bigSet, this.bigExceptSet)
      }
      if (passSettings["numbers"]){
        result += setRandom("numbers", this.numberSet, this.numberExceptSet)
      }
      if (passSettings["spec"]){
        result += setRandom("spec", this.specSet, this.specExceptSet)
      }

      result = getShuffleArr(result)
      return result
    },
    genPassButton(){
      this.resultsPasswords = []
      for (let i=0; i<this.variantsPass; i++){
        this.resultsPasswords.push(this.genPassword())
      }
    },

    async copyPassToClipboard(event){
      clearTimeout(this.timeoutHideTooltip);
      const eventTarget = event.target;
      if(eventTarget.tagName === 'P'){
        try {
          // записываем текст в буфер
          await navigator.clipboard.writeText(eventTarget.textContent);
        } catch (e) {
          console.error(e);
        }
        this.tooltipText = "Скопировано в буфер";
        if (this.$refs.tooltip.attributes['hidden']){
          this.$refs.tooltip.attributes.removeNamedItem('hidden')
        }
        event.target.appendChild(this.$refs.tooltip);
        this.timeoutHideTooltip = setTimeout(this.hideTooltip, 950)
      }
    },
    hideTooltip(){
      if (!this.$refs.tooltip.attributes['hidden']) {
        this.$refs.tooltip.setAttribute('hidden', 'true');
      }
    },
  },
  watch:{
    lengthPass(value){
      if (value > 32){
        this.lengthPass = 32
      }
      if (value < 0){
        this.lengthPass = 6
      }
    },
    variantsPass(value){
      if (value > 10){
        this.variantsPass = 10
      }
      if (value < 0){
        this.variantsPass = 3
      }
    }
  }
}
</script>

<style scoped>
.description-page{
  text-align: left;
  padding: 0 20px;
}
.description-page h1{
  text-align: center;
  margin-bottom: 0;
}
.description-page h3{
  text-align: center;
  margin: 0;
}
.description-page p{
  margin-bottom: 0;
  font-size: 0.85em;
}
.description-page ul{
  margin-top: 0;
  font-size: 0.85em;
}

.pass-gen{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "lft res rght";
  grid-gap: 20px;
  list-style: none;
  margin:0 0 20px;
  padding: 10px 20px;
}
.settings-left{
  grid-area: lft;
}
.settings-right{
  grid-area: rght;
}
.results{
  grid-area: res;
}

.pass-gen .item{
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 0;
}

.pass-gen .item-on::before{
  content: '☑ '
}

.pass-gen .item-off{
  color: #575757;
}

.pass-gen .item-off::before{
  content: '☐ ';
}

.description{
  font-size: 0.7em;
  margin-top: 0;
}
.results{
  height: 100%;
  overflow: auto;
}
.results .window{
  min-height: 85%;
  max-height: 100%;
  overflow: auto;
  scroll-margin-bottom: 2px;
  box-sizing: border-box;
  overflow-y: hidden;
}
.results .window p{
  display: block;
  position: relative;
  margin: 5px 0;
  padding: 0 5px;
  white-space: nowrap;
  cursor: pointer;
}

.len-pass, .variant-pass{
  padding: 0 20px 20px;
  margin-top: 20px;
}

@media screen and (max-width:800px){
  .pass-gen{
    grid-template-columns: 1fr;
    grid-gap: 0;
    grid-template-areas:
      "lft"
      "rght"
      "res"
  }
  .results{
    height: 80vh;
  }
}
</style>