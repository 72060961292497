<template>
  <div class="separator">
  </div>
  <footer>
    <div><p>© Menlor.ru {{new Date().getFullYear()}}</p></div>
    <div class="icons-social"><p><a href="https://t.me/menlor" target="_blank"><img :src="'/icons/icon_telegram.svg'" alt="Telegram"> </a></p></div>
  </footer>
</template>

<script>
export default {
  name: "FooterBottom"
}
</script>

<style scoped>
footer{
  display: grid;
  grid-template-columns: 240px  240px;
  justify-content: space-between;
  list-style: none;
  gap: 40px;
  margin:0;
  padding: 0 10px;
  margin-bottom: 20px;
}
footer p{
  margin: 0;
}
footer div{
  padding: 0 10px;
  width: max-content;
  align-self: center;
}

footer div:last-child{
  justify-self: end;
}
.icons-social a{
 border-bottom: 0;
}
p {
  text-align: left;
}

@media screen and (max-width:800px){
  footer{
    grid-template-columns: 1fr;
  }
  footer div:last-child {
    justify-self: start;
  }
}

</style>