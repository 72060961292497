<template>
  <HeaderTop/>
<!--  <PassGenerator/>-->
  <router-view></router-view>
  <FooterBottom/>
</template>

<script>
import HeaderTop from "@/components/HeaderTop";
import FooterBottom from "@/components/FooterBottom";
// import HelloWorld from "@/components/HelloWorld";
// import PassGenerator from "@/components/PassGenerator";

export default {
  name: 'App',
  components: {
    FooterBottom,
    HeaderTop,
    // HelloWorld,
    // PassGenerator,
  }
}
</script>

<style>

</style>
