<template>
  <h1>Домашняя</h1>
  <h3>Определенно, ничего определенного.</h3>
  <h3>Позже будет бложик, но там тоже ничего определенного не планируется )))</h3>
  <p>На самом деле, просто нужно было сделать главную страницу и что-то на ней написать, но что писать я хз, поэтому воткну ещё картинку, чтобы забить пустоту.</p>
  <img :src="indexImg" class="responsive">
</template>

<script>
import indexImg from '../../public/img/mem_index.jpg'
export default {
  name: "IndexPage",
  data(){
    return{
      indexImg: indexImg,
  }
  }
}
</script>

<style scoped>
  h1, h3{
    margin: 0;
  }
</style>