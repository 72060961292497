<template>
  <header>
    <ul class="menu-header">
      <li>
        <ul id="left-menu">
          <li>
<!--            <a href="#">Генератор паролей</a>-->
            <router-link to="/generator-paroley">Генератор паролей</router-link>
          </li>
        </ul>
      </li>
      <li><router-link to="/" id="logo-link"><img id="logo-menu" :src="logo" alt="Logo menlor.ru"></router-link></li>
      <li>
        <ul id="right-menu">
          <li><a href="#">Будет Бложик</a></li>
        </ul>
      </li>
    </ul>
  </header>
  <div class="separator">
  </div>
</template>

<script>
import logo from '../../public/img/menlor_logo_120.svg'
export default {
  name: "HeaderTop",
  data(){
    return {
      logo: logo
    }
  },
}
</script>

<style scoped>
  .menu-header{
    display: grid;
    grid-template-columns: 1fr 120px 1fr;
    list-style: none;
    margin:0;
    padding: 10px 0;
  }
  #left-menu,
  #right-menu{
    display: flex;
    list-style: none;
    margin:0;
    padding: 0;
    height: 100%;
    box-sizing: border-box;
    flex-wrap: wrap;
    word-wrap: anywhere;
  }
  #left-menu{
    justify-content: right;
  }
  #right-menu{
    justify-content: left;
  }
  #left-menu li,
  #right-menu li{
    margin: 0;
    padding: 10px;
    display: flex;
    align-items: center;
  }
  .menu-header li a{
    display: block;
    margin: auto;
    padding: 5px 10px;
    border: 2px solid black;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  #logo-link{
    padding: 0;
    border:0;
  }
  /*#logo-menu{*/
  /*  width: 120px;*/
  /*  height: auto;*/
  /*}*/
</style>