<template>
  <h1>404</h1>
  <h2>Нет такой старницы.</h2>
</template>

<script>
export default {
  name: "PageNotFound404"
}
</script>

<style scoped>
  h1 {font-size: 10em; margin:0}
  h2 {font-size: 2em; margin-top:0}
</style>