import {createApp, h} from 'vue'
import App from './App.vue'
// import VueRouter from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import './main.css'
import PassGenerator from "@/components/PassGenerator";
import PageNotFound404 from "@/components/PageNotFound404";
import IndexPage from "@/components/IndexPage";
// import HelloWorld from "@/components/HelloWorld";

const routes = [
    { path: '/', component: IndexPage},
    { path: '/generator-paroley', component: PassGenerator },
    { path: '/:pathMatch(.*)*', component: PageNotFound404} ,
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

const app = createApp({
    render: ()=>h(App)
})

app.use(router)
app.mount('#app')

/*
import Vue from "vue";
import App from './App.vue'
import VueRouter from "vue-router";
import PassGenerator from "@/components/PassGenerator";
import './main.css'

Vue.use(VueRouter);

const routes = [
    {
        path: "",
        component: PassGenerator,
    },
];

const router = new VueRouter({
    routes,
    mode: "history",
});

Vue.config.productionTip = false;

new Vue({
    router,
    render: (h) => h(App),
}).$mount("#app");
*/
